//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "EmptyEntityCardsSlider",
  components: {
    EmptyDefaultText: () => import("@/components/common/EmptyDefaultText"),
    EmptyEntityCard: () => import("@/components/common/EmptyEntityCard"),
  },
  props: {
    gap: {
      type: Number,
      default: () => 32
    },
    columns: {
      type: Number,
      default: () => 3
    }
  },
  computed: {
    ...mapGetters({
      width: 'viewport/width',
    }),
    style() {
      return {
        'grid-template-columns': `repeat(${this.columns}, 1fr)`,
        'grid-gap': `${this.gap}px`,
      }
    }
  },
};
