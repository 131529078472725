//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {parser} from "@/utils/parser";

export default {
  name: "SectionCustomSliderLanding",
  components: {
    EmptyEntityCardsSlider: () => import("@/components/common/EmptyEntityCardsSlider"),
    SliderWithBullets: () => import("@/components/common/SliderWithBullets"),
    Scroller: () => import("@/components/common/Scroller"),
    Section: () => import("@/components/common/Section"),
    PlaceCard: () => import("@/components/common/PlaceCard"),
    RouteCard: () => import("@/components/common/RouteCard"),
    ExcursionCard: () => import("@/components/common/ExcursionCard"),
    EventCard: () => import("@/components/common/EventCard"),
  },
  props: {
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    },
    query: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      cards: [],
      title: "",
      show: false,
      pending: true,
    };
  },
  computed: {
    defaultQuery() {
      return {
        position: "landing-page",
        lang: this.$i18n.locale,
        resolution: "micro",
        count: 100500
      };
    },
    queryParams() {
      return {
        ...this.defaultQuery,
        ...this.query
      };
    },
    gap() {
      return this.$mediaSwitch({hd: 32, sm: 8, defaultValue: 24});
    },
    columns() {
      return this.$mediaSwitch({md: 2, sm: 1, defaultValue: 3});
    }
  },
  mounted() {
    this.$axios.$get(`/api/sliders?${parser({
      params: this.queryParams
    })}`)
      .then((resolve) => {
        if (resolve.data.length > 0) {
          this.show = true
          this.$axios.$get(`/api/sliders/${resolve.data[0].id}?${parser({
            params: this.queryParams
          })}`)
            .then((resolve) => {
              const main = resolve.data;
              if (main) {
                this.title = main.title || "";
                if (main.places.length > 0) {
                  const places = main.places.map(item => {
                    return {
                      props: {...item},
                      component: "PlaceCard"
                    };
                  });
                  this.cards = [...this.cards, ...places];
                }
                if (main.routes.length > 0) {
                  const routes = main.routes.map(item => {
                    return {
                      props: {...item},
                      component: "RouteCard"
                    };
                  });
                  this.cards = [...this.cards, ...routes];
                }
                if (main.excursions.length > 0) {
                  const excursions = main.excursions.map(item => {
                    return {
                      props: {...item},
                      component: "ExcursionCard"
                    };
                  });
                  this.cards = [...this.cards, ...excursions];
                }
                if (main.events.length > 0) {
                  const events = main.events.map(item => {
                    return {
                      props: {...item},
                      component: "EventCard"
                    };
                  });
                  this.cards = [...this.cards, ...events];
                }
              }
              this.cards.length > 0 ? this.show = true : this.show = false;
            })
            .catch(() => null)
            .finally(() => {
              this.pending = false;
            });
        } else {
          this.pending = false;
        }
      })
      .catch(() => null);
  }
};
