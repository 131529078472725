//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "EmptyEntityCard"
};
